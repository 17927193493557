.menuItem {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 10px;
    max-width: 330px;
    width: 100%;
}

.itemContent {
    margin-right: 10px;
    position: relative;
    flex-basis: 60%;
}

.itemTitle {
    margin: 0px;
}

.itemDescription {
    text-align: left;
    margin: 12px 0px;
}

.itemAppearance {
    text-align: right;
    flex-basis: 40%;
}
