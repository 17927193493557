.container {
    display: flex;
    flex-direction: column; /* Расположить элементы по вертикали */
    gap: 10px; /* Отступ между элементами */
    align-items: center; /* Центрировать элементы по горизонтали */
    margin-bottom: 10px; /* Отступ снизу */
}

.container select,
.container input {
    max-width: 250px; /* Максимальная ширина для полей ввода */
    width: 100%; /* Занять всю доступную ширину до max-width */
    padding: 8px; /* Отступ внутри полей */
    border: 2px solid var(--main-color); /* Граница с использованием основной темы */
    box-sizing: border-box; /* Учитывать границы в размере */
    font-size: 1.1rem; /* Размер шрифта */
    border-radius: 4px; /* Скругление углов */
}
