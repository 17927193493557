.header {
    margin: 10px auto;
    position: sticky;
    top: 0;
    padding: 20px 0px;
    background: var(--tg-theme-bg-color, white);
    z-index: 1;
    max-width: 700px;
}

.headerLogo {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.buttonsIcon {
    color: var(--main-color);
    font-size: 2.2rem;
    cursor: pointer;
}

.logo {
    height: 30px;
    background-image: url('../../../public/logo-new-mobile.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.logoLink {
    width: 100%;
}

.dishCount {
    display: block;
    position: absolute;
    top: 20px;
    right: 5px;
    padding: 0 5px;
    color: #fff;
    font-weight: normal;
    background: #f35050;
    border-radius: 50%;
    font-size: 0.8rem; /* Опционально, для лучшего отображения */
}
