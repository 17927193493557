.resultsContainer {
    position: fixed;
    top: 65px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--tg-theme-bg-color, rgba(255, 255, 255, 0.95));
    z-index: 1000;
    overflow-y: auto;
    padding: 20px;
    max-width: 700px;
    margin: 0 auto;
}

.resultItem {
    margin-bottom: 15px; /* Отступ между элементами */
}
