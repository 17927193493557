.itemButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}

.iconBag {
    font-size: 1.3rem;
    margin-right: 5px;
}

.itemAmоunt {
    margin: 0 10px;
    font-size: 1.5rem;
}
