.priceWrapper {
    /* margin: 5px 0; */
    text-align: left;
    display: flex;
    align-items: baseline;
}

.priceWrapper span {
    margin-right: 10px;
}

.itemPriceBold,
.sum {
    font-weight: bold;
    font-size: 1.5rem;
}

.itemCurrencyBold {
    font-weight: normal;
    font-size: 1.1rem;
}

.sum {
    margin-left: auto;
}
