.container {
    max-width: 380px; /* Ширина контейнера */
    margin: 10px auto; /* Центрирование контейнера с отступом */
}

.dateContainer {
    display: flex; /* Используем flexbox для выравнивания */
    align-items: center; /* Вертикальное выравнивание по центру */
    justify-content: center; /* Горизонтальное выравнивание по центру */
    margin-bottom: 15px; /* Отступ снизу для контейнера */
}

label {
    white-space: nowrap; /* Предотвращаем перенос текста в подписи */
    margin: 0 10px; /* Отступ между подписью и полем */
}

.deliveryDate,
.timeSelect {
    font-size: 1.1rem; /* Размер шрифта */
    padding: 7px; /* Отступ внутри поля */
    border: 2px solid #4da29b; /* Цвет рамки */
    border-radius: 5px; /* Закругление углов */
    transition: border-color 0.3s; /* Плавный переход для изменения цвета рамки */
}

.deliveryDate {
    width: 150px; /* Ширина поля даты */
}

.timeContainer {
    display: flex; /* Используем flexbox для выравнивания в одну строку */
    justify-content: center; /* Центрируем элементы */
    margin-top: 10px;
}

.timeSelect {
    width: 220px; /* Ширина селекта времени */
    margin-left: 10px; /* Отступ между label и селектом */
}

.errorMessage {
    color: red; /* Цвет текста ошибки */
    margin-top: 5px; /* Отступ сверху */
}

.deliveryOptionContainer {
    margin-bottom: 15px;
}

.toggleOption {
    display: none; /* Скрываем сами радио-кнопки */
}

.toggleOptionLabel {
    display: inline-block;
    position: relative;
    width: 240px; /* Ширина переключателя */
    height: 48px; /* Высота переключателя */
    border-radius: 10px; /* Закругление углов */
    cursor: pointer; /* Курсор-указатель при наведении */
    line-height: 48px; /* Центрирование текста по вертикали */
    padding-left: 40px; /* Отступ слева для кружка */
    text-align: left; /* Выравнивание текста по левому краю */
    transition: background-color 0.3s ease, box-shadow 0.3s ease, opacity 0.3s ease; /* Плавный переход для фона, контура и прозрачности */
    opacity: 0.7; /* Полупрозрачный текст по умолчанию */
}

.toggleOptionLabel::before {
    content: '';
    position: absolute;
    left: 10px; /* Позиция кружка слева */
    top: 50%; /* Центрирование по вертикали */
    transform: translateY(-50%); /* Центрирование по вертикали */
    width: 20px; /* Ширина кружка */
    height: 20px; /* Высота кружка */
    border-radius: 50%; /* Круглая форма */
    background-color: transparent; /* Прозрачный фон по умолчанию */
    border: 2px solid #4da29b; /* Цвет бордюра */
    transition: background-color 0.3s ease; /* Плавный переход цвета */
}

.toggleOption:checked + .toggleOptionLabel::before {
    background-color: #4da29b; /* Цвет кружка при выборе */
}

.toggleOption:checked + .toggleOptionLabel {
    box-shadow: 0 0 0 4px rgba(102, 204, 194, 0.5); /* Контур вокруг выбранного элемента */
    background-color: rgba(118, 238, 226, 0.2); /* Легкая заливка для выбранного элемента */
    opacity: 1; /* Полная яркость текста при выборе */
}

.toggleOption:not(:checked) + .toggleOptionLabel {
    transition: background-color 0.3s ease; /* Плавный переход для фона при переключении */
}
