.informationView {
    margin: 10px auto;
    display: flex;
    flex-direction: column;
}

.informationView p,
ul {
    margin: 2px;
}

.location {
    margin-bottom: 5px;
    text-align: center; /* Центрирование текста в элементе */
}

@media (max-width: 400px) {
    .informationView {
        margin: 10px 15px; /* Устанавливаем отступы для меньших экранов */
    }
}
