.container {
    margin-bottom: 15px;
}

.toggleLocation {
    display: none; /* Скрываем сами радио-кнопки */
}

.toggleLocationLabel {
    display: inline-block;
    position: relative;
    width: 300px; /* Ширина переключателя */
    height: 48px; /* Высота переключателя */
    border-radius: 10px; /* Закругление углов */
    cursor: pointer; /* Курсор-указатель при наведении */
    line-height: 48px; /* Центрирование текста по вертикали */
    text-align: left; /* Выравнивание текста по левому краю */
    padding-left: 40px; /* Отступ слева для кружка */
    transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Плавный переход для фона и контура */
    opacity: 0.7; /* Полупрозрачный текст по умолчанию */
}

.toggleLocationLabel::before {
    content: '';
    position: absolute;
    left: 10px; /* Позиция кружка слева */
    top: 50%; /* Центрирование по вертикали */
    transform: translateY(-50%); /* Центрирование по вертикали */
    width: 20px; /* Ширина кружка */
    height: 20px; /* Высота кружка */
    border-radius: 50%; /* Круглая форма */
    background-color: transparent; /* Прозрачный фон по умолчанию */
    border: 2px solid #66ccc2; /* Цвет бордюра */
    transition: background-color 0.3s ease; /* Плавный переход цвета */
}

.toggleLocation:checked + .toggleLocationLabel::before {
    background-color: var(--main-color); /* Цвет кружка при выборе */
    border-color: #66ccc2; /* Цвет бордюра при выборе */
}

.toggleLocation:checked + .toggleLocationLabel {
    box-shadow: 0 0 0 4px rgba(102, 204, 194, 0.5); /* Контур вокруг выбранного элемента */
    background-color: rgba(118, 238, 226, 0.2); /* Легкая заливка для выбранного элемента */
    opacity: 1; /* Полная яркость текста при выборе */
}

.toggleLocation:not(:checked) + .toggleLocationLabel {
    opacity: 0.7; /* Полупрозрачный текст при невыборе */
    transition: background-color 0.3s ease; /* Плавный переход для фона при переключении */
}
