.conteiner {
    max-width: 1200px;
    margin: 10px auto;
    /* padding: 20px; */
    /* background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
