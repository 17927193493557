.closeModal {
    position: absolute;
    top: 15px;
    right: 20px;
    width: 24px;
    height: 24px;
    opacity: 0.2;
    cursor: pointer;
    transition: opacity 0.3s; /* Добавление эффекта при наведении */
}

.closeModal:hover {
    opacity: 1; /* Увеличение видимости при наведении */
}

.closeModal::before,
.closeModal::after {
    content: '';
    position: absolute;
    top: 10px;
    display: block;
    width: 24px;
    height: 3px;
    background: var(--tg-theme-text-color, black);
}

.closeModal::before {
    transform: rotate(45deg);
}

.closeModal::after {
    transform: rotate(-45deg);
}

.title {
    margin: 25px 5px 5px;
}

.textWrapper {
    margin: 0 10px;
}

.description {
    text-align: left;
}
