.loadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 250px); /* Центрируем с учетом отступа */
    margin-top: 50px; /* Отступ сверху */
}

.loadingRing {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-left-color: var(--main-color); /* Цвет вращающейся части */
    border-radius: 50%;
    width: 50px; /* Размер кольца */
    height: 50px; /* Размер кольца */
    animation: spin 1s linear infinite; /* Анимация вращения */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
