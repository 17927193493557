.content {
    margin: 0 20px;
}

.content strong {
    margin-left: 10px;
    color: var(--tg-theme-text-color, black);
}

.contacts {
    font-size: 1.2rem;
}

.phone,
.messagers {
    margin-top: 10px;
}

.phone svg,
.messagers svg {
    vertical-align: middle; /* Выравнивание иконок с текстом */
}
