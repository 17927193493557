.headMenuItem {
    background-color: var(--main-color);
    margin-right: 8px;
    padding: 5px 15px;
    border-radius: 10px;
    flex-shrink: 0;
    font-size: 1.2rem;
    color: var(--tg-theme-bg-color, white);
    text-decoration: none;
}

.headMenuItem.active {
    background-color: var(--main-active-color);
}

.headMenuItem a {
    color: var(--tg-theme-text-color, white);
    text-decoration: none;
}
