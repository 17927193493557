.informationView {
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    text-align: left;
}

@media (max-width: 400px) {
    .informationView {
        margin: 10px 15px; /* Устанавливаем отступы для меньших экранов */
    }
}
