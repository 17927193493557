.container {
    margin: 10px auto;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 380px;
}

h2,
h3 {
    margin: 10px;
}

.commentInput {
    max-width: 300px;
    width: 100%;
    padding: 8px;
    border: 2px solid var(--main-color);
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 10px 0;
    transition: border-color 0.3s;
}

.checkboxGroup {
    margin-bottom: 15px;
}

.checkboxGroup input {
    display: none;
}

.labelNoCallback {
    display: inline-block;
    position: relative;
    width: 162px;
    height: 50px;
    border-radius: 25px;
    cursor: pointer;
    background-color: #ccc;
    color: darkgray;
    font-size: 20px;
    line-height: 50px;
    padding: 0 14px 0 10px;
    text-align: right;
    transition: background-color 0.3s, color 0.3s;
}

.labelNoCallback::after {
    content: '';
    position: absolute;
    top: 3px;
    left: 4px;
    width: 44px;
    height: 44px;
    background-color: #fff;
    border-radius: 50%;
    transition: left 0.3s, background-color 0.3s;
}

.checkboxNoCallback:checked + .labelNoCallback {
    background-color: var(--main-color);
    text-align: left;
    color: var(--tg-theme-bg-color, white);
}

.checkboxNoCallback:checked + .labelNoCallback::after {
    left: 138px;
    background-color: #f7f7f7;
}

.btnSubmit {
    width: 300px;
    height: 50px;
    background-color: #76eee2;
    color: var(--tg-theme-bg-color, white);
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 5px 20px;
    font-size: 1.4rem;
    font-weight: bold;
    border: 2px solid var(--main-active-color);
}

.error {
    margin: 10px;
    color: red;
}
.without {
    margin: 10px;
    color: red;
}

.groupPay {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 1.1rem;
}

.btnPay {
    border: 2px solid var(--main-color);
    border-radius: 5px;
    background-color: #dbdbda;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 10px 10px 10px;
    cursor: pointer;
    margin-left: 15px;
    transition: filter 0.3s, transform 0.1s; /* Добавлено свойство для анимации */
}

.btnPay:hover {
    filter: brightness(90%);
}

.btnPay:active {
    transform: scale(0.95); /* Сжатие при нажатии */
}

.payImage {
    height: 24px;
    max-width: 100%;
    margin-left: 8px;
    align-self: center;
}

.checkboxGroupBirthday {
    margin: 20px auto;
}

.labelBirthday {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkboxGroupBirthday input {
    display: none;
}

.customCheckbox {
    width: 22px;
    height: 22px;
    border: 2px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    position: relative;
}

.customCheckboxChecked {
    background-color: var(--main-color);
    border-color: var(--main-color);
}

.checkmark {
    color: white;
    font-size: 22px;
}

.myOrders {
    font-size: 22px;
    color: blue;
    font-weight: bold;
}
