.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.modalContent {
    padding: 10px;
    border-radius: 10px;
    background-color: var(--tg-theme-bg-color, white);
    max-width: 500px;
    width: 90vw;
    position: relative;
}
