.imageWrapper,
.imageWrapperModal {
    position: relative; /* Позволяет позиционировать вложенные элементы */
    margin: 0 auto; /* Центрирует контейнер */
}

.imageWrapperModal {
    width: 250px; /* Ширина для модального окна */
}

.buttonFavoriteList {
    position: absolute; /* Абсолютное позиционирование */
    border: none; /* Убирает границу кнопки */
    background-color: unset; /* Убирает фон кнопки */
    font-size: 1.4rem; /* Размер шрифта */
    color: red; /* Цвет кнопки */
    top: 5px; /* Отступ сверху */
    right: 5px; /* Отступ справа */
    padding: 0; /* Убирает внутренние отступы */
    margin: 0; /* Убирает внешние отступы */
    cursor: pointer; /* Указывает, что элемент кликабельный */
    transition: color 0.3s ease; /* Плавный переход цвета */
}

.itemImage {
    height: 125px; /* Высота изображения по умолчанию */
    cursor: pointer;
}

.itemImageModal {
    height: 250px; /* Высота изображения в модальном режиме */
}

.discountTextModal,
.discountText {
    color: white; /* Цвет текста */
    font-weight: bold; /* Жирный текст */
    font-size: 0.8rem; /* Размер шрифта */
}

.discountTextModal {
    font-size: 1.1rem; /* Размер шрифта */
}

.productLabelModal,
.productLabel {
    position: absolute; /* Абсолютное позиционирование */
    top: 0; /* Верхний край */
    left: 5px; /* Левый край */
    width: 32px; /* Ширина прямоугольника */
    height: 20px; /* Высота прямоугольника */
    background-color: #ff6125; /* Красный цвет */
    display: flex; /* Центрирование содержимого */
    align-items: center; /* Вертикальное центрирование */
    justify-content: center; /* Горизонтальное центрирование */
}

.productLabelModal {
    width: 44px; /* Ширина прямоугольника */
    height: 26px; /* Высота прямоугольника */
}

.productLabel::after {
    content: ''; /* Пустое содержимое для псевдоэлемента */
    position: absolute; /* Абсолютное позиционирование */
    bottom: -7px; /* Сдвиг вниз */
    left: 50%; /* Центрирование по горизонтали */
    transform: translateX(-50%); /* Центрирование по горизонтали */
    width: 0; /* Ширина треугольника */
    height: 0; /* Высота треугольника */
    border-left: 16px solid transparent; /* Левый треугольник */
    border-right: 16px solid transparent; /* Правый треугольник */
    border-top: 7px solid #ff6125; /* Цвет треугольника */
}

.productLabelModal::after {
    content: ''; /* Пустое содержимое для псевдоэлемента */
    position: absolute; /* Абсолютное позиционирование */
    bottom: -10px; /* Сдвиг вниз */
    left: 50%; /* Центрирование по горизонтали */
    transform: translateX(-50%); /* Центрирование по горизонтали */
    width: 0; /* Ширина треугольника */
    height: 0; /* Высота треугольника */
    border-left: 22px solid transparent; /* Левый треугольник */
    border-right: 22px solid transparent; /* Правый треугольник */
    border-top: 10px solid #ff6125; /* Цвет треугольника */
}
