.product,
.lastProduct {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 10px;
    flex: 0 1 320px;
    max-width: 320px;
    /* border-bottom: 1px solid gray; */
    /* padding-bottom: 10px; */
}
.lastProduct {
    border-bottom: 1px solid gray;
    padding-bottom: 10px;
}

.productImage {
    height: 100px;
    margin-right: 40px;
}

.productInfo {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
}

.productName {
    margin-bottom: 15px;
    text-align: left;
}

.productSum {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}

.sumValue {
    font-weight: bold;
    font-size: 1.5rem;
    margin-left: 5px;
    margin-right: 5px;
}

.quantityControls {
    display: flex;
    align-items: center;
    margin-top: auto;
}

.btnDecrease,
.btnIncrease {
    background-color: #76eee2;
    border: none;
    color: var(--tg-theme-bg-color, white);
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 5px;
    font-size: 1.4rem;
    font-weight: bold;
}

.btnDecrease:hover,
.btnIncrease:hover {
    background-color: #66ccc2;
}

.quantity {
    margin: 0 10px;
    font-size: 1.5rem;
}

.btnRemove {
    background: none;
    color: var(--tg-theme-text-color, red);
    font-size: 22px;
    border: none;
    cursor: pointer;
    position: absolute;
    right: -10px;
    top: -20px;
    padding: 0;
    font-weight: bold;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.modalImage {
    width: 350px;
    height: 350px;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}
