.container {
    margin-bottom: 15px;
}

.checkboxGroup {
    margin-bottom: 15px; /* Отступ между группами чекбоксов */
}

.checkboxGroup input {
    display: none; /* Скрываем стандартные чекбоксы */
}

.labelDelivery,
.labelPickup {
    display: inline-block;
    position: relative;
    width: 138px; /* Ширина чекбокса */
    height: 50px; /* Высота чекбокса */
    border-radius: 25px; /* Закругление углов */
    cursor: pointer; /* Курсор-указатель при наведении */
    background-color: #ccc; /* Цвет фона по умолчанию */
    /*color: var(--tg-theme-bg-color, black); /* Цвет текста */
    color: darkgray;
    font-size: 20px; /* Размер шрифта */
    line-height: 50px; /* Центрирование текста по вертикали */
    padding-right: 10px; /* Отступ справа для текста */
    padding-left: 14px; /* Отступ слева для текста */
    text-align: right; /* Выравнивание текста по правому краю */
    transition: background-color 0.3s ease, color 0.3s ease; /* Плавный переход цвета фона и текста */
}

.labelDelivery::after,
.labelPickup::after {
    content: '';
    position: absolute;
    top: 3px; /* Отступ сверху для кружка */
    left: 4px; /* Позиция кружка слева */
    width: 44px; /* Ширина кружка */
    height: 44px; /* Высота кружка */
    background-color: #fff; /* Цвет фона кружка */
    border-radius: 50%; /* Круглая форма */
    transition: left 0.3s ease, background-color 0.3s ease; /* Плавные переходы для положения и цвета */
}

/* Стиль для активного состояния */
.checkboxDelivery:checked + .labelDelivery {
    background-color: var(--main-color); /* Цвет фона при выборе */
    text-align: left; /* Выравнивание текста по левому краю при выборе */
    /*color: white; /* Цвет текста при выборе */
    color: var(--tg-theme-bg-color, white);
}

.checkboxDelivery:checked + .labelDelivery::after {
    left: 115px; /* Позиция кнопки переключателя при выборе */
    background-color: #f7f7f7; /* Цвет фона кружка при выборе */
}

.checkboxPickup:checked + .labelPickup {
    background-color: var(--main-color); /* Цвет фона при выборе */
    text-align: left; /* Выравнивание текста по левому краю при выборе */
    color: var(--tg-theme-bg-color, white);
    /*color: white; /* Цвет текста при выборе */
}

.checkboxPickup:checked + .labelPickup::after {
    left: 115px; /* Позиция кнопки переключателя при выборе */
    background-color: #f7f7f7; /* Цвет фона кружка при выборе */
}
