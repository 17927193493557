.container {
    max-width: 350px;
    margin: 10px auto;
}

.title {
    text-align: center;
}

.paymentOptions {
    display: flex;
    justify-content: center;
    margin: 15px 0;
}

.paymentOption {
    flex: 1;
    /* margin: 0 2px; Отступы между кнопками */
}

.radioInput {
    display: none; /* Скрыть стандартные радио-кнопки */
}

.radioLabel {
    display: block; /* Занимаем всю ширину родителя */
    background-color: #e0e0e0;
    padding: 10px 10px; /* Отступы для меток */
    border-radius: 5px;
    cursor: pointer;
    color: var(--tg-theme-bg-color, white);
    /*transition: background-color 0.3s, transform 0.2s; /* Плавное изменение цвета и масштабирование */
    text-align: center; /* Выравнивание текста по центру */
    margin: 0 7px;
}

.radioInput:checked + .radioLabel {
    background-color: var(--main-color); /* Цвет выбранной опции */
    /*border: 2px solid var(--main-color); /* Цвет рамки */
    /*   color: #fff; /* Цвет текста выбранной опции */
    /* transform: scale(1.05); /* Увеличиваем элемент при выборе */
}
