.textMenu {
    margin: 10px 0;
    color: var(--main-color);
}

.container {
    margin: 0 auto;
    max-width: 600px;
}

.categories {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
}

.category {
    width: 150px;
}

.categoryContent {
    border-top: 4px solid var(--main-color);
    border-radius: 10px;
    box-shadow: 0 2px 16px 0 rgba(6, 2, 102, 0.07);
    cursor: pointer;
    overflow: hidden;
    transition: box-shadow 0.3s, border-top 0.3s;
}

.categoryContent:hover {
    box-shadow: 0px 4px 16px 0px rgba(102, 204, 194, 0.66);
    border-top: 4px solid var(--main-active-color);
}

.title {
    font-size: 1.3rem;
    color: var(--tg-theme-text-color, var(--main-color));
    text-decoration: none;
}

.imageContainer {
    height: 100px;
    overflow: hidden; /* Скрывает лишние части изображения */
}
.itemImage {
    object-fit: contain;
    height: 100%;
    width: auto;
}
