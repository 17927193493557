.searchContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--tg-theme-bg-color, rgba(255, 255, 255, 0.95));
    z-index: 1000;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    max-width: 700px;
    margin: 0 auto;
}

.searchContainer input {
    max-width: 260px;
    width: 100%;
    font-size: 1rem;
    padding: 8px;
    border: 2px solid var(--main-color);
    border-radius: 4px;
}

.closeButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    align-self: flex-end;
    margin-bottom: 20px;
    margin-top: -40px;
    transition: color 0.3s; /* Плавный переход цвета */
}

.closeButton:hover {
    color: red; /* Изменение цвета при наведении */
}
