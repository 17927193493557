.container {
    max-width: 600px;
    margin: 15px;
}

.ordersTitle {
    font-size: 24px;
    font-weight: bold;
    margin: 20px;
    text-align: center;
}

.ordersList {
    list-style: none;
    padding: 0;
}

.orderItem {
    margin-bottom: 15px;
}

.orderHeader {
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    text-align: left;
    margin-bottom: 10px;
}

.orderDate,
.deliveryLocation,
.deliveryTiming {
    margin-bottom: 5px;
}

.orderActions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.repeatButton {
    padding: 8px 16px;
    background-color: var(--main-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.repeatButton:hover {
    background-color: var(--main-active-color);
}

.iconReload {
    color: var(--main-color);
    font-size: 2.2rem;
    cursor: pointer;
}

.product {
    display: flex;
    justify-content: space-between;
    padding: 6px 0;
    border-bottom: 1px solid #ddd;
}

.productNumber {
    font-weight: bold;
    text-align: center;
    width: 6%;
}

.productTitle {
    font-weight: bold;
    text-align: left;
    width: 50%;
}

.productAmount {
    width: 8%;
    text-align: center;
}
.productSum {
    width: 16%;
    text-align: center;
}
.totalSum {
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: right;
}

.loading {
    text-align: center;
    font-size: 18px;
    color: #666;
    padding: 20px;
}

.error {
    text-align: center;
    font-size: 18px;
    color: #e74c3c;
    padding: 20px;
    border-radius: 5px;
}

.noOrders {
    text-align: center;
    font-size: 18px;
    color: #888;
    padding: 20px;
}
