.menu {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    transform: translateX(-200%);
    transition: transform 0.4s;
    z-index: 101; /* Обеспечьте, чтобы меню было выше, чем блур */
}

.menu.active {
    transform: translateX(0);
}

.blur {
    height: 100vh;
    width: 100vw;
    backdrop-filter: blur(3px);
    position: absolute;
    z-index: 100;
}

.myOrders {
    font-weight: bold;
}

.menuContent {
    width: 320px;
    height: 100vh;
    background-color: var(--tg-theme-bg-color, white);
    padding-top: 20px;
    position: relative;
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.2);
}

.closeMenu {
    position: absolute;
    top: 15px;
    right: 20px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.closeMenu::before,
.closeMenu::after {
    content: '';
    position: absolute;
    top: 10px;
    width: 24px;
    height: 3px;
    background: var(--main-color);
}

.closeMenu::before {
    transform: rotate(45deg);
}

.closeMenu::after {
    transform: rotate(-45deg);
}

.navList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px;
}

.navItem {
    color: var(--tg-theme-text-color, black);
    margin-bottom: 20px;
    text-decoration: none;
    font-size: 1.2rem;
    transition: font-weight 0.2s; /* Добавим плавный переход для font-weight */
}

.navItem:hover {
    font-weight: bold;
}

.navContent {
    display: flex;
    align-items: center; /* Изменено на center для более гармоничного выравнивания */
}

.navContentMenu {
    display: flex;
    align-items: center; /* Изменено на center для более гармоничного выравнивания */
    color: var(--main-color);
}

.icon {
    font-size: 1.3rem;
    color: var(--main-color);
    margin-right: 10px;
}
